import '@reach/menu-button/styles.css'
import '~/styles/global.css'

import { ApolloProvider } from '@apollo/client'
import { useFocusVisible } from '@react-aria/interactions'
import { OverlayProvider } from '@react-aria/overlays'
import { ErrorBoundary, init } from '@sentry/react'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { ReactNode, useState } from 'react'
import { Provider as ReakitProvider } from 'reakit'
import { ThemeProvider } from 'styled-components'
import { SEO } from '~/components/seo'
import { env } from '~/config/env'
import { AuthProvider } from '~/context/auth'
import { ToastsProvider } from '~/context/toasts'
import { useApollo } from '~/hooks/use-apollo'
import { useFathomAnalytics } from '~/hooks/use-fathom-analytics'
import { DialogsProvider } from '~/system/dialogs/dialog'
import { PosthogProvider } from '~/providers/PosthogProvider'

import type { GraphQLError } from 'graphql'
import { useRouter } from 'next/router'

const NotFound = dynamic(() => import('~/pages/not-found'))
// const Intercom = dynamic(() => import('~/components/intercom'), { ssr: false })
const LiveSession = dynamic(() => import('~/components/live-session'), {
  ssr: false,
})

const theme = {
  space: [],
  breakpoints: ['640px', '768px', '1024px'],
  mediaQueries: {
    small: `@media screen and (min-width: 640px)`,
    medium: `@media screen and (min-width: 768px)`,
    large: `@media screen and (min-width: 1024px)`,
  },
}

init({
  dsn: env.sentry.dsn,
  release: env.sentry.release,
  enabled: process.env.NODE_ENV === 'production',
})

const App = ({ Component, pageProps, router }: AppProps) => {
  const [_, setError] = useState<GraphQLError>()
  // @ts-ignore TODO fix
  const apolloClient = useApollo(setError)

  useFathomAnalytics()
  useFocusVisible()

  // @ts-ignore
  let getLayout = Component.getLayout || ((page: JSX.Element) => page)

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta name="theme-color" content="#5653ff" />
        <link
          rel="preload"
          as="font"
          href="/fonts/graphik-regular.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href="/fonts/graphik-medium.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href="/fonts/graphik-bold.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
      <SEO
        title="Kindest"
        description="Kindest uses smart automations, robust CRM and beautiful fundraising websites to help nonprofits like you raise more money."
        hideKindest
      />
      <Providers pageProps={pageProps}>
        {getLayout(<Component {...pageProps} />)}
        {router.pathname.startsWith('/sign-in') && <LiveSession />}
        {router.pathname.startsWith('/sign-up') && <LiveSession />}
      </Providers>
    </>
  )
}

const Providers = ({ children, pageProps }: { children: ReactNode, pageProps: any }) => {
  const { pathname } = useRouter()
  const [error, setError] = useState<GraphQLError>()
  // @ts-ignore TODO fix
  const apolloClient = useApollo(setError)

  if (error?.extensions?.type === 'not_found') {
    return <NotFound />
  }

  // TODO check if wrapping these two in dynamic components works better for embeddable pages
  if (pathname.endsWith('/embedded-form')) {
    return (
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <ReakitProvider>
            <ToastsProvider>
              {children}
              <DialogsProvider />
            </ToastsProvider>
          </ReakitProvider>
        </ThemeProvider>
      </ApolloProvider>
    )
  }

  return (
    <ErrorBoundary>
      <PosthogProvider bootstrapData={pageProps.bootstrapData}>
        <ApolloProvider client={apolloClient}>
          <OverlayProvider>
            <ThemeProvider theme={theme}>
              <ReakitProvider>
                <AuthProvider>
                  <ToastsProvider>
                    {children}
                    <DialogsProvider />
                  </ToastsProvider>
                </AuthProvider>
              </ReakitProvider>
            </ThemeProvider>
          </OverlayProvider>
        </ApolloProvider>
      </PosthogProvider>
    </ErrorBoundary>
  )
}

export default App
