import { useMemo } from 'react'
import { env } from '~/config/env'

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject, split,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
// import { concatPagination } from '@apollo/client/utilities'

import introspection from '~/graphql/introspection'
import type { TypedTypePolicies as TypePolicies } from '~/graphql/type-policies'
import { GraphQLError, GraphQLFormattedError } from 'graphql'
import { accessToken } from '~/utils/access-token'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
// import { captureMessage, Severity } from '@sentry/react'

let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null

type ErrorHandler = (error: GraphQLFormattedError) => void

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      // replyTasks: {
      //   keyArgs: ['workspaceId', 'filter'],
      // },
      // page(_, { args, toReference }) {
      //   return toReference({
      //     __typename: 'Page',
      //     id: args!.id,
      //   })
      // },

      automation(_, { toReference, args }) {
        return toReference({
          __typename: 'Automation',
          type: args!.type,
        })
      },
    },
  },
  AutomationSettings: {
    keyFields: () => [],
  },
  Automation: {
    keyFields: ({ type }) => {
      return `Automation:${type}`
    },
  },
  UnreadReplyCount: {
    keyFields: ['workspaceId'],
  },
  Institution: {
    fields: {
      contacts: {
        merge: false,
      },
    },
  },
  Contact: {
    fields: {
      institutions: {
        merge: false,
      },
    },
  },
}

function createApolloClient(errorHandler?: ErrorHandler) {
  const httpLink = new HttpLink({
    uri: env.apiUrl + '/graphql',
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  })

  const wsUrl = env.apiUrl.replace(/^http:/, 'ws:').replace(/^https:/, 'wss:');
  console.log('wsUrl', wsUrl)
  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsUrl,
    })
  )

  const authLink = setContext((_, { headers }) => {
    // get the authentication token if it exists
    const token = accessToken.get('dashboard')

    if (!token || headers?.['Authorization']) {
      return {
        headers,
      }
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    }
  })

  const convertFormattedErrorToGraphQLError = (formattedError: any): GraphQLError => {
    return new GraphQLError(
      formattedError.message,
      undefined,
      undefined,
      undefined,
      formattedError.path,
      undefined,
      formattedError.extensions
    );
  };

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const { message } = error
        console.warn(
          `[GraphQL Error]: Message: ${message} in ${operation.operationName}`
        )
      })

      errorHandler?.(convertFormattedErrorToGraphQLError(graphQLErrors[0]))
    }
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink
  )

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: errorLink.concat(authLink.concat(splitLink)),
    // connectToDevTools: true,
    cache: new InMemoryCache({
      possibleTypes: introspection.possibleTypes,
      typePolicies,
    }),
  })
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null = null,
  errorHandler?: ErrorHandler
) {
  const apolloClient = globalApolloClient ?? createApolloClient(errorHandler)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    const existingCache = apolloClient.extract()
    apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return apolloClient
  }

  // Create the Apollo Client once in the client
  if (!globalApolloClient) {
    globalApolloClient = apolloClient
  }

  return apolloClient
}

export type InitialApolloProps = {
  initialApolloState?: NormalizedCacheObject
}

export function useApollo(errorHandler: ErrorHandler) {
  const store = useMemo(() => initializeApollo({}, errorHandler), [])
  return store
}
